@import "normalize.css";

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "proxima-nova", sans-serif;
}

input,
button {
  font-family: inherit;
}

main {
  padding: 20px;
}
.project-list {
}

.message {
  padding: 10px;

  background-color: green;

  &.error {
    background-color: red;
  }

  &.hide {
    display: none;
  }
}

.admin-body header {
  padding: 20px;

  background-color: #e5e5e5;
}

.project-form {
  padding-bottom: 3em;

  label {
    display: block;

    margin: 0 0 1em;

    span {
      display: block;
      font-weight: bold;
    }
  }
}

.project {
  display: block;

  margin: 20px 0;

  border-bottom: 1px solid red;

  &-actions {
    &:before {
      display: block;

      width: 100%;

      content: "Actions";
      font-weight: bold;
    }

    list-style: none;

    display: flex;
    flex-wrap: wrap;

    padding: 0;
    margin: 30px 0 10px;

    li {
      margin-right: 10px;
    }
  }

  &-password {
    display: block;

    &:before {
      content: "Password: ";
      font-weight: bold;
    }
  }

  &-link {
    display: block;

    &:before {
      content: "Link: ";
      font-weight: bold;
    }
  }
}

.front-header {
  display: flex;

  justify-content: space-between;

  padding: 20px;

  color: white;

  svg {
    width: 180px;
    height: 17px;
  }
}

.initial {
  position: fixed;

  top: 50%;
  left: 50%;

  max-width: 450px;
  width: 80%;

  transform: translate3d(-50%, -50%, 0);

  z-index: 5;
}

.front-form {
  display: flex;

  border: 1px solid white;

  padding: 5px 10px;
  background-color: black;

  input,
  button {
    border: 0;
    background-color: transparent;

    font-size: 13px;

    font-weight: 500;

    letter-spacing: 1px;

    outline: 0;
  }

  button {
    background-color: white;
    color: black;
    font-weight: 700;

    padding: 8px 12px 7px;
    text-transform: uppercase;

    cursor: pointer;

    transition: {
      duration: 0.15s;
      property: opacity;
    }

    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }

  input {
    flex-grow: 1;

    padding: 4px 10px;
    color: white;

    &::placeholder {
      color: white;
    }

    &:focus::placeholder {
      color: rgba(255, 255, 255, 0.25);
    }
  }
}

.front-message {
  text-align: center;

  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;

  padding: 10px;

  color: white;

  &.hide {
    display: none;
  }
}

.front-body {
  display: flex;

  flex-direction: column;
  height: 100vh;

  background-color: #000;
}

.table {
  width: 100%;
  margin: 0 auto;
  background: #eeeeee;

  flex-grow: 1;
  // border: 1px #cbcbcb solid;

  opacity: 0;
  visibility: hidden;

  .transition & {
    transition: {
      duration: 0.25s;
      property: opacity, visibility;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }

  iframe {
    width: 100%;
    height: 100%;
    border-bottom: 1px #cbcbcb solid;
  }
}
